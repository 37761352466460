import { Injectable, OnInit, Output, EventEmitter } from '@angular/core';




import { Http, RequestOptions, Headers } from '@angular/http';
import { HttpClient, HttpParams } from '@angular/common/http';


import { Subject, Observable } from 'rxjs';
import { EventManager } from '@angular/platform-browser';
import { NotificationService } from '../shared/services/notification.service';
import { MsAdalAngular6Service } from 'microsoft-adal-angular6';
import { Router } from '@angular/router';


@Injectable()
export class LayoutService implements OnInit {
  @Output() profileUpdateEvent: EventEmitter<any> = new EventEmitter<any>();

 public coachingRoutingObject:any = {};

authToken:string;

  migrationID: string;
  businessName: string;

  migration: any;

  updatedMigration: any;
  isEditing = false;

  private agentTickets: Array<any>;

  agentFullname: string;

  partnerId: string;

  myTeam: string;

  isAdmin =false;


  public agentLoaded: Subject<any>;
  public agentEmail: string;

  public msalUser:any;

public userRoles:Array<string>;



  public profileLoaded: Observable<any>;



  // booking date
  bookingDate:Date;
hoursAvailable:number;
roles:Array<string>;


isSupportStaff =false;

supportStaffProfile:any ;

isScoutDashboardAdmin =false;

currentCoachedAmbassador = '';
currentCoachedEmail ='';


scoutDashboardAdmins:Array<string> = [ 'bishop.duperret@maaxmicrosoft.com','jason.weeks@maaxmicrosoft.com', 'adine.godfrey@maaxmicrosoft.com',
'jacob.lee@maaxmicrosoft.com'
];



  constructor(private http: HttpClient , private msalService: MsAdalAngular6Service, private router:Router) {
    this.agentLoaded = new Subject<any>();



this.msalUser = this.msalService.LoggedInUserName;

this.CheckIfScoutDashboardAdmin();

this.getUserRoles( );




setInterval( () => {


  if ( this.GetMaaxmicrosoftEmail() !== undefined && this.GetMaaxmicrosoftEmail().toLowerCase() != null && this.GetMaaxmicrosoftEmail().toLowerCase() !== '') {
  if (this.GetMaaxmicrosoftEmail().toLowerCase().indexOf('maaxmicrosoft') < 0) {

    this.router.navigate(['../login']);

    console.log('butts') ;
  }
}
  }, 2000 );





 setInterval(  () => {

  this.getUserRoles( );
  this.profileUpdateEvent.emit(this.isAdmin);





  this.msalService.acquireToken('ad4e5d9c-71e6-4495-9bc9-9905f5345949').subscribe((res: string) => {this.authToken =  res ; console.log( 'token ' + this.authToken) ;



}


  ); }, 20000);





      if ( localStorage.getItem('isAdmin')  != null ) {
        if (  localStorage.getItem('isAdmin').toUpperCase() === 'TRUE') {

              this.isAdmin = true;

        }
      }
      try {

      //  this.LoadTickets();

      } catch (error) { console.log( error); }



      //  this.GetMyTeam();    // removed due to raveusers








  }


  ngOnInit() {


    this.CheckIfSupportStaff();



try {

    this.GetTickets();

  } catch (error) { console.log( error); }



    this.GetMyTeam();
  }

  public GetTickets(): Array<any> {

    if (this.agentTickets != null || this.agentTickets !== undefined) {
      return this.agentTickets;
    }


    return new Array<any>();


  }
  public SetDate (day: Date, hours: number) {

this.bookingDate = day;

this.hoursAvailable = hours;

  }


  getUserRoles( ) {


    console.log('agent email ' + this.GetMaaxmicrosoftEmail() );
    const test = 'https://ticketscout.azurewebsites.net/api/AMPUser?code=SIi9Saxwz1u7gyZu/XKuikAvZ7aSAvV9y5IwCUk1ANHtCnbPBizq6Q==&email=' + this.GetMaaxmicrosoftEmail()  ;
    this.http.get(test   )
    .toPromise()
    .then(response => {


      if ( response != null && response !== undefined) {
      this.userRoles = (response as any).roles;
      }
      console.log('users roles ' + JSON.stringify( this.userRoles ) );

      } );



  }


public async getAuthToken () {

    const token = '';

return  this.msalService.acquireToken('ad4e5d9c-71e6-4495-9bc9-9905f5345949').toPromise();



}

public GetEmployeeProfile () {

 const uri =  'https://ticketscout.azurewebsites.net/api/AMPUser?code=SIi9Saxwz1u7gyZu/XKuikAvZ7aSAvV9y5IwCUk1ANHtCnbPBizq6Q==' + '&email=' + this.msalService.LoggedInUserEmail;
console.log('user roles ' + uri);
  return   this.http.get(uri)
  .toPromise();


}
  public GetMyTeam ()  {

     const test = 'https://ticketscout.azurewebsites.net/api/RaveUsers?code=04laSQx8UGfezunnVbaDaO3evbQJchfU4saNt6rk/AybqtTL5OfiVw==&SipUsers=true';

     this.http.get(test   )
     .toPromise()
     .then(response => {





      const users  = response as Array<any>;
      console.log(  users) ;

      for ( let i = 0; i < users.length; i++ ) {

        if ( users[i].agentEmail === this.agentEmail) {


          this.myTeam = users[i].SubOrg;


       console.log ( '  team ' + this.myTeam);
       console.log ( ' Test ' + JSON.stringify( this.agentTickets));

        this.IsAdmin( this.myTeam);




       break;


      }


      }

     }).catch(() => {  });




  }

  public GetMaaxmicrosoftEmail (): string {

   return this.msalService.LoggedInUserEmail;
  }
  public GetMaaxFullname (): string {
    return this.msalService.LoggedInUserName;

  }

  public GetUserRole ( ): Promise<any> {

    return   this.http.get('https://ticketscout.azurewebsites.net/api/AMPUser?code=SIi9Saxwz1u7gyZu/XKuikAvZ7aSAvV9y5IwCUk1ANHtCnbPBizq6Q==' + '&email=' + this.msalUser.displayableId)
    .toPromise();

  }


  public LoadTickets(): Promise<any> {






    const promise = new Promise((resolve, reject) => {




      const user = this.msalService.LoggedInUserName;

      this.msalUser = user;


      const name = user.name;
      const email = this.msalService.LoggedInUserEmail;


      console.log('my name ' + name );
      console.log('my email ' + email );

    //


         console.log( JSON.stringify( user) );




              this.http.get('https://ticketscout.azurewebsites.net/api/RaveTicket?code=mWbvRLNcROEtJLT2ZVAQlnIx/G27NibmXbV08VDWQN7KRXa4AaspaA==&name=' + name + '&maaxEmail=' + email)
                .toPromise()
                .then(response => {

                  console.log(' $$$ test'   );

                  this.agentTickets = response as Array<any>;
                  this.partnerId = this.agentTickets[0].partnerId;
                  this.agentEmail = this.agentTickets[0].agentEmail;
                  this.agentFullname = this.agentTickets[0].fullName;

                  console.log(' fullname ' + this.agentFullname);


                  this.GetMyTeam();


                  this.profileUpdateEvent.emit( this.partnerId);


                  resolve();

                }).catch(() => { // reject()
                });








    });


    return promise;



  }


  public CheckIfScoutDashboardAdmin  () {

    if ( this.scoutDashboardAdmins.includes(this.msalService.LoggedInUserEmail.toLowerCase()  )) {
      this.isScoutDashboardAdmin = true;
      console.log( ' $$$ admin') ;

    }
  }

  public CheckIfSupportStaff () {

    console.log ( '@@@ ' + this.agentEmail );

    this.GetSupportStaff().then (  res => {


      const staff = res as Array<any>;

      console.log( staff);


      for ( let i = 0; i < staff.length; i++) {

        console.log ( '@@@ ' + this.agentEmail );


        if ( (staff[i].MaaXmicrosoftEmail as string).toLowerCase()  === this.agentEmail.toLowerCase()) {

          this.isSupportStaff = true;
          this.supportStaffProfile = staff[i];



        }
      }


    });

  }

  public GetSupportStaff (): Promise<any> {

    // Begin assigning parameters

  return this.http.get('https://ticketscout.azurewebsites.net/api/ScoutDashboard?code=K0b6qSUFLJ4gaubXhHiQa3qdp2ou5LciZiIoVyZgHZ9NpCYok0t4ww==&msftEmployees=true').toPromise() ;

}

  public GetAdmin (): boolean {
    return this.isAdmin;

  }

  public  IsAdmin( subOrg:  string): boolean    {
    if ( this.myTeam.toUpperCase() === 'SALMON SALMONS') {



      this.isAdmin = true;
          localStorage.setItem('isAdmin', 'true' );

          this.profileUpdateEvent.emit(this.isAdmin);

          return true;

    } else {

      console.log(' IS ADMIN');

      this.isAdmin = false;
          localStorage.setItem('isAdmin', 'false' );

          this.profileUpdateEvent.emit(this.isAdmin);

          return false;

    }

  }

public GetBookingDate(): any  { return  { bookingDate:  this.bookingDate , hoursAvailable: this.hoursAvailable};

}


  public LoadTicket2(email: string): Promise<any> {

    if (localStorage.getItem('office365SupportEmail')) {
      this.agentEmail = localStorage.getItem('office365SupportEmail');

      email = this.agentEmail;

    }



    const promise = new Promise((resolve, reject) => {




      this.http.get('https://ticketscout.azurewebsites.net/api/RaveTicket?code=mWbvRLNcROEtJLT2ZVAQlnIx/G27NibmXbV08VDWQN7KRXa4AaspaA==&partnerId=' + '\'' + this.partnerId + '\'')
        .toPromise()
        .then(response => {

          this.agentTickets = response as Array<any>;





          this.agentFullname = this.agentTickets[0].AgentFirstName + ' ' + this.agentTickets[0].AgentLastName;




          this.partnerId = this.agentTickets[0].PartnerId;





          console.log(' partnerID: ' + this.partnerId);

          this.profileUpdateEvent.emit(this.partnerId);


          resolve();


        }).catch(() => reject('Failed to load data'));








    });



    return promise;




  }

/**
  public GetMyTeam  ()  {

    const uri = 'https://ticketscout.azurewebsites.net/api/RaveUsers?code=04laSQx8UGfezunnVbaDaO3evbQJchfU4saNt6rk/AybqtTL5OfiVw==';
    const promise = this.http.get(uri )

    .toPromise().then ( res => {

      const users = res as Array<any>;

      for ( let i = 0; i < users.length; i++) {

        if ( users[i].agentEmail === this.agentEmail) {

          this.myTeam = users[i].subOrg;

          this.agentFullname = users[i].agentFirstName + ' ' + users[i].agentLastName;

          console.log( 'TEAM ' + this.myTeam);

          if ( this.myTeam.toUpperCase() === 'SALMON SALMONS') {



            this.isAdmin = true;
                localStorage.setItem('isAdmin', 'true' );

                this.profileUpdateEvent.emit(this.isAdmin);
          } else {

            console.log(' IS ADMIN');

            this.isAdmin = false;
                localStorage.setItem('isAdmin', 'false' );

                this.profileUpdateEvent.emit(this.isAdmin);
          }




        }


      }


    })
     ;



   }

*/




}

