import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthGuard } from './shared';
import { LayoutService } from './layout/layout.service';
import { ServiceWorkerModule, SwPush } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { NewsletterService } from './shared/services/newsletter.service';
import { PushNotificationsService } from './shared/services/push-notification.service';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireModule } from '@angular/fire';
import { MessagingService } from './shared/services/messaging.service';
import { AsyncPipe } from '../../node_modules/@angular/common';
import { NotificationService } from './shared/services/notification.service';
import { LoginService } from './login/login.service';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { LoaderComponent} from './shared/components/loader/load.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import {} from 'jasmine';
import { MsAdalAngular6Module } from 'microsoft-adal-angular6';
import { AuthenticationGuard } from 'microsoft-adal-angular6';

// AoT requires an exported function for factories
export const createTranslateLoader = (http: HttpClient) => {
    /* for development
    return new TranslateHttpLoader(
        http,
        '/start-angular/SB-Admin-BS4-Angular-6/master/dist/assets/i18n/',
        '.json'
    ); */
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
};

@NgModule({
    imports: [
        CommonModule ,
        BrowserModule ,
        BrowserAnimationsModule ,
        AngularFireDatabaseModule ,
        AngularFireAuthModule ,
        AngularFireMessagingModule ,
        FormsModule  ,
        NgbModule  ,
        FlexLayoutModule  , 
        MsAdalAngular6Module.forRoot({
            tenant: '70c276c3-887d-4067-a86f-3514a90fb297', 
            clientId: 'ad4e5d9c-71e6-4495-9bc9-9905f5345949', 
            redirectUri: window.location.origin,
            endpoints: { 

              "https://localhost:4200": "xxx-bae6-4760-b434-xxx",
            
            },
            navigateToLoginRequestUrl: false,
            cacheLocation: 'localStorage',  
          }),
    AngularFireModule.initializeApp(environment.firebase),
        HttpClientModule,  
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient]
            }
        }),
        AppRoutingModule,
     
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
        ServiceWorkerModule.register('firebase-messaging-sw.js', { enabled: environment.production }) ,
      
      
    ],
    declarations: [AppComponent],
    providers: [AuthenticationGuard,NotificationService,FormsModule, LayoutService, NewsletterService , PushNotificationsService,MessagingService ,AsyncPipe , LoginService   ,
    
         ],
    bootstrap: [AppComponent]  ,
    exports: [ ]
   
})
export class AppModule {}
 