import { Component, OnInit } from '@angular/core';
import {SwPush } from '@angular/service-worker';
import { NewsletterService } from './shared/services/newsletter.service';
import { MessagingService } from './shared/services/messaging.service';
import { BehaviorSubject } from 'rxjs';
 
import { LayoutService } from './layout/layout.service';
import { PushNotificationsService } from './shared/services/push-notification.service';
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

    message: BehaviorSubject <any>;


      constructor( private pushNotification:PushNotificationsService, private newsletterService: NewsletterService, private messagingService: MessagingService, private layoutservice: LayoutService) {
   
 
        console.log( "got this far");
      //  this.subscribeToNotifications();

      
    //  this.pushNotification.requestPermission();
      
      this.messagingService.requestPermission("bob");

      this.messagingService.receiveMessage();

      this.message = this.messagingService.currentMessage;

      Notification.requestPermission().then(function(result) {
        if (result === 'denied') {
          console.log('Permission wasn\'t granted. Allow a retry.');
          return;
        }
        if (result === 'default') {
          console.log('The permission request was dismissed.');
          return;
        }

        console.log("%%%%%%%%%%%%%%%%%%%00");
        
        // Do something with the granted permission.
      });

  this.layoutservice.LoadTickets().then(  ()=> { 
      
      const userId = 'user001';
    

      console.log( "got this far");

   
}
  );


   

    }

    ngOnInit() {

       
    }

     
}
 