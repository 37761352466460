import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { AuthGuard } from './shared';
import { AuthenticationGuard } from 'microsoft-adal-angular6';
const routes: Routes = [
    { path: '', loadChildren: './layout/layout.module#LayoutModule', canActivate: [AuthenticationGuard] },
    
    { path: 'login', loadChildren: './login/login.module#LoginModule' },
    { path: 'claim', loadChildren: './email-claims/claims.module#ClaimsModule' },
 
 
    { path: 'signup', loadChildren: './signup/signup.module#SignupModule' },
    { path: 'error', loadChildren: './server-error/server-error.module#ServerErrorModule' },
    { path: 'access-denied', loadChildren: './access-denied/access-denied.module#AccessDeniedModule' },
    { path: 'not-found', loadChildren: './not-found/not-found.module#NotFoundModule' },
    { path: 'support', loadChildren: './support-dashboard/support-dashboard.module#SupportDashboardModule' },
    { path: '**', redirectTo: 'not-found' } 
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {}
