
///<reference path='../../../../node_modules/@types/winrt-uwp/index.d.ts' />
import {

    Injectable

} from '@angular/core';

import {

    Observable

} from 'rxjs';

@Injectable()

export class PushNotificationsService {

    public permission: Permission;

    constructor() {

        this.permission = this.isSupported() ? 'default' : 'denied';

    }

    public isSupported(): boolean {

        return 'Notification' in window;

    }

    requestPermission(): void {

   


        let self = this;

        if ('Notification' in window) {

          
            Notification.requestPermission(function(status) {

                console.log( "}}}}}}}}}}}}}}}}}}}}}} "+ status);


                return self.permission = status;

            });

        }

    }

    create(title: string, options ? : PushNotification): any {

        let self = this;

        return new Observable(function(obs) {

            if (!('Notification' in window)) {

                console.log('Notifications are not available in this environment');

                obs.complete();

            }

            if (self.permission !== 'granted') {

                console.log("The user hasn't granted you permission to send push notifications");

                obs.complete();

            }

            let _notify = new Notification(title, options);

            _notify.onshow = function(e) {

                return obs.next({

                    notification: _notify,

                    event: e

                });

            };

            _notify.onclick = function(e) {

                return obs.next({

                    notification: _notify,

                    event: e

                });

            };

            _notify.onerror = function(e) {

                return obs.error({

                    notification: _notify,

                    event: e

                });

            };

            _notify.onclose = function() {

                return obs.complete();

            };

        });

    }

    generateNotification(source: Array < any > ): void {

        let self = this;

        source.forEach((item) => {

            let options = {

                body: item.alertContent,

                icon: "../resource/images/bell-icon.png"

            };

            let notify = self.create(item.title, options).subscribe();

        })

    }

    public CreateNotification ( message:string) {

        if   (typeof Windows !== 'undefined' &&
        typeof Windows.UI !== 'undefined' &&
        typeof Windows.UI.Notifications !== 'undefined') {

            if (message)
            this.showToast ( message, "");



        }else {

          
        }
    }

    //////////////////////////////////////////////////
    //**                                               */
  //**                                               */
  //**                                               */
 //////////////////////////////////////////////////

 public showToast(message, iconUrl) {
    if (typeof Windows !== 'undefined' &&
      typeof Windows.UI !== 'undefined' &&
      typeof Windows.UI.Notifications !== 'undefined') {

     

      var notifications = Windows.UI.Notifications;
      var template = notifications.ToastTemplateType.toastImageAndText01;
      var toastXml = notifications.ToastNotificationManager.getTemplateContent(template);
      var toastTextElements = toastXml.getElementsByTagName("text");
      toastTextElements[0].appendChild(toastXml.createTextNode(message));
      
      
      
      var toastImageElements = toastXml.getElementsByTagName("image");
      var newAttr = toastXml.createAttribute("src");
      newAttr.value = iconUrl;


      var altAttr = toastXml.createAttribute("alt");
      altAttr.value = "toast graphic";
      var attribs = toastImageElements[0].attributes;
      attribs.setNamedItem(newAttr);
      attribs.setNamedItem(altAttr);

  
 

      var toast = new notifications.ToastNotification(toastXml);
      var toastNotifier = notifications.ToastNotificationManager.createToastNotifier();
      toastNotifier.show(toast);
    }   
  }   

public updateTile(message, imgUrl, imgAlt) {
  if (typeof Windows !== 'undefined' &&
    typeof Windows.UI !== 'undefined' &&
    typeof Windows.UI.Notifications !== 'undefined') {
    var date = new Date();
    var notifications = Windows.UI.Notifications,
      tile = notifications.TileTemplateType.tileSquare150x150PeekImageAndText01,
      tileContent = notifications.TileUpdateManager.getTemplateContent(tile),
      tileText = tileContent.getElementsByTagName('text');
    tileText[0].appendChild(tileContent.createTextNode(message || date.toTimeString()));
    var tileImage = tileContent.getElementsByTagName('image');
    var newAttr = tileContent.createAttribute("src");
    newAttr.value = imgUrl || 'https://unsplash.it/150/150/?random';
    var altAttr = tileContent.createAttribute("alt");
    altAttr.value = imgAlt || 'Random demo image';
    var attribs = tileImage[0].attributes;
    attribs.setNamedItem(newAttr);
    attribs.setNamedItem(altAttr);
    var tileNotification = new notifications.TileNotification(tileContent);
    var currentTime = new Date();
    tileNotification.expirationTime = new Date(currentTime.getTime() + 600 * 1000);
    notifications.TileUpdateManager.createTileUpdaterForApplication().update(tileNotification);
  }
}

}

export class WindowsNotification implements PushNotification {

   

      public   body: string;
    
      public  icon   : string;
    
      public  tag   : string;
    
      public  data   : any;
    
      public  renotify  : boolean;
    
      public  silent   : boolean;
    
      public  sound   : string;
    
      public  noscreen   : boolean;
    
      public  sticky  : boolean;
    
      public  dir  : 'auto' | 'ltr' | 'rtl';
    
      public  lang   : string;
    
      public  vibrate  : number[];
    
     

}

export declare type Permission = 'denied' | 'granted' | 'default';

export interface PushNotification {

    body ? : string;

    icon ? : string;

    tag ? : string;

    data ? : any;

    renotify ? : boolean;

    silent ? : boolean;

    sound ? : string;

    noscreen ? : boolean;

    sticky ? : boolean;

    dir ? : 'auto' | 'ltr' | 'rtl';

    lang ? : string;

    vibrate ? : number[];

}