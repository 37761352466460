import { Injectable, Component, EventEmitter, Output
 } from '@angular/core';

 import {HttpClient, HttpParams} from '@angular/common/http';

import { LayoutService } from 'src/app/layout/layout.service';
import { unescapeIdentifier } from '@angular/compiler';

@Injectable()
export class NotificationService {
    @Output() notificationsUpdated: EventEmitter<any> = new EventEmitter<any>();

public notifications: Array <any> ;

public unclearedNotificationCount = 0;



  constructor( private http: HttpClient, private layoutService: LayoutService
    ) {


        this.notifications = new Array<NotificationDBObject>();


        this.layoutService.profileUpdateEvent.subscribe(  () => {this.notifications2(); } );


setInterval( () => { this.notifications2(); } , 20000 );

Notification.requestPermission().then(function(result) {
    if (result === 'denied') {
      console.log('Permission wasn\'t granted. Allow a retry.');
      return;
    }
    if (result === 'default') {
      console.log('The permission request was dismissed.');
      return;
    }
    // Do something with the granted permission.
  });


    }


    public ClearNotifications() {






        this.ClearNotificationsServerSide().then( res =>    this.notifications.forEach( elem => elem.Cleared = true)   );
        this.unclearedNotificationCount = 0;
        this.notifications2();




    }



    public notifications2 () {



      if ( this.layoutService != null && this.layoutService !== undefined)  {
     if ( this.layoutService.partnerId != null && this.layoutService.partnerId !== undefined) {

       this.GetNotifications().then( res =>  {
      this.notifications = res as Array< any> ;

        this.processNotifications();


       this.notificationsUpdated.emit(this.notifications);


    }

     );
    }
}
}

    public processNotifications () {

        this.unclearedNotificationCount = 0;


        this.notifications.forEach( el => {

                el.CreatedTime =   ( new Date( Date.parse( el.CreatedTimeStamp + 'Z'))  ).toLocaleString();


                if ( !el.Cleared) {
                    this.unclearedNotificationCount++;

                }



                    if ( el.NotificationType === 0) {




                        const time: Date = new Date( Date.parse(  el.NoteAddedTimeStamp + 'Z' ) );




                            el.Title = 'Case Reopened';


                        el.Body = 'Ticket #' + el.TicketNumber + ' has been reopened'   ;



                    }  else if (el.NotificationType === 1 ) {


                        const time: Date = new Date(   el.NoteAddedTimeStamp + 'Z'   );



                        el.Title = 'New External Message added';
                        el.Body = 'Ticket #' + el.TicketNumber + ' has a new message added at ' + time.toLocaleString();

                    } else if ( el.NotificationType === 2) {
                        const time: Date = new Date( Date.parse(  el.NoteAddedTimeStamp + 'Z' ) );



                        el.Title = 'Acw > 10 mins ';
                        el.Body = ' Please be sure to exit ACW as soon as possible \n'  ;

                    }

                    else if ( el.NotificationType===3  ) {
                        const time: Date = new Date( Date.parse(  el.NoteAddedTimeStamp + 'Z' ) );



                        el.Title = 'Rating <= 3 stars';
                        el.Body = el.Message ;
                    }

                    else if ( el.NotificationType===4   ) {
                        const time: Date = new Date( Date.parse(  el.NoteAddedTimeStamp + 'Z' ) );



                        el.Title = '5 stars, Resolved & Comment';
                        el.Body = el.Message ;
                    }

                    



            }



            );

    }

    public ClearNotificationsServerSide (): Promise<any> {

        const params = new HttpParams();

        // Begin assigning parameters



      const  add: string = '\'' + this.layoutService.partnerId + '\'' ;


    return this.http.post('https://ticketscout.azurewebsites.net/api/Notifications?code=9ixivT97aU935asMg8tuYqlw3qZ6azxhEdxHkZ0c8Ef4Vs0sZObrfA==&partnerId=' + add,

JSON.stringify( {'clearNotifications': true}))
          .toPromise() ;




    }


public GetNotifications (): Promise<any> {



    const params = new HttpParams();

    // Begin assigning parameters



  const  add: string = '\'' + this.layoutService.partnerId + '\'' ;


return this.http.get('https://ticketscout.azurewebsites.net/api/Notifications?code=9ixivT97aU935asMg8tuYqlw3qZ6azxhEdxHkZ0c8Ef4Vs0sZObrfA==&partnerId=' + add)
      .toPromise() ;




  }




}
export class NotificationDBObject {

    public   TicketNumber: number;
    public   NoteAddedTimeStamp: Date;
    public   ContactOrigin: string;
    public   RequestId: string;

    public   Cleared: boolean;
    public   NotificationType: number;
    public   PartnerId: string;
    public   notificationType: number;


    public   AgentEmail: string;
    public   ClearedTimeStamp: Date;
    public   Message: string;
    public   CreatedTimeStamp: Date;

}

export   class AMPNotification  {

public read: Boolean;
public dateCreated: Date;

public clearedTime: Date;
public title: string;
public body: string;


public constructor ( dateCreated, title, body,  ) {
    this.read = false;
    this.dateCreated = dateCreated;
    this.title = title;
    this.body = body;

}


}
