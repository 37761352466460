import { Injectable , OnInit, Output,EventEmitter} from '@angular/core';
 
 
 
 
import { Http, RequestOptions, Headers }       from '@angular/http';
 import {HttpClient, HttpParams} from '@angular/common/http';
 

import { Subject , Observable} from 'rxjs';
import { EventManager } from '@angular/platform-browser';
import { LayoutService } from '../layout/layout.service';
 
 
@Injectable()
export class LoginService implements OnInit{
  @Output() profileUpdateEvent: EventEmitter<any> = new EventEmitter<any>();

     verifiedEmail:string;

myTeam:string;
isAdmin =false;

 
  constructor(private http: HttpClient, private layoutService: LayoutService    ) {
 


  }


  ngOnInit () {
  


  }

  public SubmitClaim( domainEmail:string,   office365email:string, fullName:string) :Promise<any> {

var obj :any ={};
obj.domainEmail =domainEmail;
obj.office365SupportEmail = office365email;

let splits: string[]   = fullName.split(" ") ;
obj.firstName = splits[0];

if ( splits.length>1) obj.lastName = splits[1];
else  obj.lastName = "";



console.log(obj);


    let uri ='https://ticketscout.azurewebsites.net/api/RaveLogin?code=rmPAyD1vSb2jS0X0GFuZIXqUdR91fwO1M99qaDur4yQDDsHJzLG2NA==&submit=true';

    let promise = this.http.post(uri ,JSON.stringify( obj)  ).toPromise()  ;
    
return promise;


  }

  public ValidateClaim( claimId:string   ) :Promise<any> {

    var obj :any ={};
    obj.claimId =claimId;
 
  
    
    console.log(obj);
    
    
        let uri ='https://ticketscout.azurewebsites.net/api/RaveLogin?code=rmPAyD1vSb2jS0X0GFuZIXqUdR91fwO1M99qaDur4yQDDsHJzLG2NA==&validate=true';
    
        let promise = this.http.post(uri ,JSON.stringify( obj)  ).toPromise()  ;
        
    return promise;
    
    
      }
    
  public GetMapping( domainEmail:string   ) :Promise<any> {

    var obj :any ={};
    obj.domainEmail = domainEmail;

 
  
    
    console.log(obj);
    
    
        let uri ='https://ticketscout.azurewebsites.net/api/RaveLogin?code=rmPAyD1vSb2jS0X0GFuZIXqUdR91fwO1M99qaDur4yQDDsHJzLG2NA==&mapping=true';
    
        let promise = this.http.post(uri ,JSON.stringify( obj)  ).toPromise()  ;
        
    return promise;
    
    
      }
    

   
  public GetMyTeam  (email)  {

    let uri='https://ticketscout.azurewebsites.net/api/RaveUsers?code=04laSQx8UGfezunnVbaDaO3evbQJchfU4saNt6rk/AybqtTL5OfiVw==';
    let promise = this.http.get(uri )
 
    .toPromise().then ( res => { 

      let users = res as Array<any>;

      for ( let i=0; i< users.length; i++) {

        if ( users[i].agentEmail ==email) {

          this.myTeam = users[i].subOrg;

          console.log( "TEAM "+ this.myTeam);

          if ( this.myTeam.toUpperCase() == "SALMON SALMONS") {
            this.isAdmin=true;
                localStorage.setItem('isAdmin', "true" );

                this.layoutService.isAdmin=true;
                
              
                this.profileUpdateEvent.emit(this.isAdmin);
          }

       


        }


      }


    })
     ;
  
  
   
   }
 

   
 

}