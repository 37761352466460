import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { mergeMapTo } from 'rxjs/operators';
import { take } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs'
import * as firebase from 'firebase/app';
import 'firebase/messaging';
import { PushNotificationsService } from './push-notification.service';
import { Http, RequestOptions, Headers }       from '@angular/http';
 import {HttpClient, HttpParams} from '@angular/common/http';
import { LayoutService } from '../../layout/layout.service';
@Injectable()
export class MessagingService {

  currentMessage = new BehaviorSubject(null);

  constructor(
    private angularFireDB: AngularFireDatabase, private pushService: PushNotificationsService,
    private angularFireAuth: AngularFireAuth,
    private angularFireMessaging: AngularFireMessaging , private http: HttpClient , private layoutService: LayoutService) {


       this.layoutService.profileUpdateEvent.subscribe( this.requestPermission("bob"));

      
    this.angularFireMessaging.messaging.subscribe(
      (_messaging) => {
        _messaging.onMessage = _messaging.onMessage.bind(_messaging);
        _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
      }
    )
  }


  public token:string ;



updateToken (  token:string ) {

if( this.layoutService.partnerId!=null &&  this.layoutService.partnerId!=undefined)
  this.updateTokenPromise(this.layoutService.partnerId, this.layoutService.agentEmail   ,token).then( () => console.log( " %%%  999 Notification SUCCESS")).catch ( error =>  console.log( error ) ) ; 
 else console.log( " partner id is null");
 

}

  updateTokenPromise(   partnerId: string, agentEmail:string, token:string  ) {
  

 

  console.log(   " notification object "+  JSON.stringify( {  "tokenUpdate":true,
  "partnerId":   partnerId ,
  
   
  "agentEmail": agentEmail,
  "token": token

}
  ));

  return  this.http.post("https://ticketscout.azurewebsites.net/api/Notifications?code=9ixivT97aU935asMg8tuYqlw3qZ6azxhEdxHkZ0c8Ef4Vs0sZObrfA==" , 

    JSON.stringify( {  "tokenUpdate":true,
    "partnerId":   partnerId ,
    
     
    "agentEmail": agentEmail,
    "token": token

  }
    ))
              .toPromise() ;
           
  }

  /**
   * update token in firebase database
   * 
   * @param userId userId as a key 
   * @param token token as a value

  updateToken(userId, token) {
    // we can change this function to request our backend service

    console.log( token) ;

    this.angularFireAuth.authState.pipe(take(1)).subscribe(
      () => {
        const data = {};
        data[userId] = token
        this.angularFireDB.object('fcmTokens/').update(data)
      })
  }
   */
  /**
   * request permission for notification from firebase cloud messaging
   * 
   * @param userId userId
   */
  requestPermission(userId) {

    console.log( "Attempting to get permission"); 
    
    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
      

        console.log( "My token "+ token);

        this.token = token;
        
        this.updateToken(token);


        this.angularFireMessaging.tokenChanges.subscribe(  (token) => {  

          console.log( "refresged token "+ token);
         
         
          this.token = token;
        
          this.updateToken(token);

        })

     
      },
      (err) => {
        console.error('Unable to get permission to notify.', err);
      }
    );
  }

  /**
   * hook method when new notification received in foreground
   */
  receiveMessage() {

    console.log(" !! 22new message received. " );

    this.angularFireMessaging.messages.subscribe(
      (payload) => {
        console.log("22new message received. ", payload);
     //   var notification = new Notification("Hi there!");
       this.pushService.showToast(payload,"");

        this.currentMessage.next(payload);
      },(err) => {
        console.error('22Unable to get permission to notify.' );
      }) ;
       
  }
}